import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import {MainText} from "../components/MainComponents";
import Helmet from "react-helmet";
import {Description, Image, Name, Person, PersonContainer} from "./common";
import styled from "styled-components";

const AbsolventCompanies = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    > img {
        margin: 2rem;
        height: 10rem;
    }

    @media (max-width: 1024px) {
        > img {
            margin: 2rem;
            height: 8rem;
        }
    }

    @media (max-width: 768px) {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: repeat(4, 1fr);
        grid-row-gap: 2rem;
        justify-items: center;
        margin: 2rem 0;

        > img {
            margin: 0.5rem;
            width: auto;
            height: auto;
        }
    }

`;


export const AboutPageTemplate = ({image, title, heading, description, content, contentComponent}) => {
    const PageContent = contentComponent || Content;

    return (
        <div className="">
            <div
                className="full-width-image margin-top-0"
                style={{
                    backgroundImage: `url("/img/layout/background-main.svg"), linear-gradient( 112.1deg,  rgba(32,38,57,1) 11.4%, rgba(63,76,119,1) 70.2% )`,
                    backgroundPosition: `top left`,
                    backgroundSize: "1920px",
                    height: "350px",
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        height: '150px',
                        lineHeight: '1',
                        justifyContent: 'space-around',
                        alignItems: 'left',
                        flexDirection: 'column',
                    }}
                >
                    <MainText>{title}</MainText>
                </div>
            </div>
            <section className="section section--gradient">
                <div className="container">
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <h3 className="has-text-weight-semibold is-size-2">{heading}</h3>
                            <p>{description}</p>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <h3 className="has-text-weight-semibold is-size-2">Zakladatelé</h3>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <PersonContainer>
                                <Person>
                                    <Image src="/img/avatars/fifo_profile.png" alt={"Filip Holec foto"}/>
                                    <Description>
                                        <Name>Filip Holec</Name>
                                        Filip je předsedou neziskové organizace TunaSec. Založil také vzdělávací
                                        akademii ENGETO, kde působí jako technický leader. V minulosti pracoval jako
                                        tester ve společnosti Red Hat a získal i vícero certifikátů.<br/><br/>
                                        Hacking je důležitou součástí jeho života z různých pohledů - například v
                                        podnikání, biohackingu, IT bezpečnosti nebo fyzické bezpečnosti (lockpicking).
                                    </Description>
                                </Person>
                                <Person>
                                    <Image src="/img/avatars/martin_zember.jpg" alt={"Martin Žember foto"}/>
                                    <Description>
                                        <Name>Martin Žember</Name>
                                        Martin působí v oblasti IT bezpečnosti více než 12 let a aktuálně pracuje jako
                                        kontraktor v oblasti red teamingu, penetračního testování, analýzy
                                        zranitelností, malware, fyzické bezpečnosti systémů a v neposlední řadě
                                        programování.<br/><br/>
                                        Pravidelně přednáší na konferencích ohledně IT bezpečnosti v ČR i v Evropě. Mimo
                                        IT bezpečnosti se věnuje i fyzické bezpečnosti – otevíraní zámků (lockpickingu).
                                    </Description>
                                </Person>
                                <Person>
                                    <Image src="/img/avatars/kamil_vavra.jpg" alt={"Kamil Vávra foto"}/>
                                    <Description>
                                        <Name>Kamil Vávra</Name>
                                        Kamil se etickým hackingem zabývá již od svých 15 let. Má bohaté znalosti získané nesčetnými hodinami výzkumu
                                        a o své poznatky se velkoryse dělí s komunitou,
                                        čímž přispívá k bezpečnějšímu prostředí internetu pro nás všechny.<br/><br/>
                                        Kamil je také zkušený bug bounty hunter a úspěšně nahlásil zranitelnosti společnostem po celém světě.
                                        <br></br>
                                        Věnuje se převážně ofenzivní webové bezpečnosti a momentálně pracuje jako Application Security Engineer.
                                    </Description>
                                </Person>
                                <Person>
                                    <Image src="/img/avatars/jan_masarik.jpg" alt={"Ján Masarik foto"}/>
                                    <Description>
                                        <Name>Ján Masarik</Name>
                                        Ján Masarik se specializuje na bezpečnost webových aplikací. Pracuje v
                                        bezpečnostním týmu Facebooku, a dříve měl na starosti bezpečnost společnosti
                                        Kiwi.com, kde založil tým specializovaný na aplikační a cloudovou
                                        bezpečnost.<br/><br/>
                                        Působí i jako konzultant pro menší firmy v oblasti bezpečnosti a je jeden z
                                        leaderů české odnože OWASP.
                                    </Description>
                                </Person>
                                <Person>
                                    <Image src="/img/avatars/petr_skyva.png" alt={"Petr Skyva foto"}/>
                                    <Description>
                                        <Name>Petr Skyva</Name>
                                        Petr je expert přes reverzní inženýrství a forenzní analýzu aplikací a binárek.
                                        Ve společnosti Cleverlance pracuje jako systémový inženýr se specializací na
                                        certifikáty, Apache Tomcat a Javu. <br/><br/>
                                        Má přesah i do DevOps, kde se specializuje na technologii Kubernetes. Působí i
                                        jako konzultant pro menší firmy v oblasti bezpečnosti. Vystudoval FIT VUT v
                                        Brně.
                                    </Description>
                                </Person>
                            </PersonContainer>
                            <PageContent className="content" content={content}/>
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <h3 className="has-text-weight-semibold is-size-2">Certifikáty</h3>
                            <p>Jako členové TunaSec máme následující certifikáty:</p>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <AbsolventCompanies>
                                <img src={"/img/pages/oscp.png"} alt="OSCP"/>
                                <img src={"/img/pages/burp_suite_pracititioner.png"} alt="Burp Suite Certified Practitioner"/>
                                <img src={"/img/pages/rhcsa.jpg"} alt="Red Hat Certified System Administrator"/>
                                <img src={"/img/pages/rhce.jpg"} alt="Red Hat Certified Engineer"/>
                                <img src={"/img/pages/google_cloud_1.png"} alt="Google Cloud Certification"/>
                                <img src={"/img/pages/google_cloud_2.png"} alt="Google Cloud Certification"/>
                            </AbsolventCompanies>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

AboutPageTemplate.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    heading: PropTypes.string,
    description: PropTypes.string,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
}

const AboutPage = ({data}) => {
    const {markdownRemark: post} = data
    const {frontmatter} = data.markdownRemark

    return (
        <Layout>
            <AboutPageTemplate
                contentComponent={HTMLContent}
                image={frontmatter.image}
                title={frontmatter.title}
                heading={frontmatter.heading}
                description={frontmatter.description}
                content={post.html}
            />
            <Helmet titleTemplate={"O nás - kdo jsme a proč to děláme | TunaSec"}>
                {/* General tags */}
                <title>O nás - kdo jsme a proč to děláme | TunaSec</title>
                <meta name="robots" content="index, follow"/>
                <meta name="keywords" content="bezpečnost, it security, it bezpečnost, kyberbezpečnost"/>
                <meta property="og:title" content="O nás - kdo jsme a proč to děláme | TunaSec"/>
                <meta name="description"
                      content="Jsme tým expertů v oblasti kyberbezpečnosti v Česku a zde si můžeš přečíst více o nás a naší motivaci a zkušenostech."/>
                <meta name="og:description"
                      content="Jsme tým expertů v oblasti kyberbezpečnosti v Česku a zde si můžeš přečíst více o nás a naší motivaci a zkušenostech."/>
                <meta name="og:image" content="https://tunasec.com/img/og-images/o-nas.png"/>
            </Helmet>
        </Layout>
    )
}

AboutPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        description
      }
    }
  }
`
