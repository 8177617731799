import styled from "styled-components";

export const MainBox = styled.div`
  display: flex;
  height: 150px;
  line-height: 1;
  justify-content: space-around;
  flex-direction: column;
`;

export const MainText = styled.h1`
    text-align: center;
    font-size: 48px;
    color: #FFFFFF;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 70px;
    
    @media (max-width: 1024px) {
        font-size: 36px;
        line-height: 50px;
    }
`;

export const SubText = styled.h3`
    text-align: center;
    font-size: 36px;
    color: #FFFFFF;
    font-weight: 400;
    margin-top: 60px;
    
    @media (max-width: 1024px) {
        font-size: 30px;
    }
`;