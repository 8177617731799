import styled from "styled-components";

export const PersonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0;
  justify-content: center;
  margin-bottom: 40px;
`;

export const Person = styled.div`
  margin: 150px 10px 0;
  box-shadow: 0 3px 10px 0 rgba(0,0,0,0.08);
  border-radius: 6px;
  text-align: center;
  width: 320px;
  @media (max-width: 768px) {
    margin: 150px 0 0;
  }
`;

export const Image = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-size: cover;
  margin: 0 auto;
  margin-top: -75px;
  background-position: center;
`

export const Description = styled.div`
  padding: 20px;
  text-align: left;
`;

export const Name = styled.div`
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 20px 0;
`;

export const ProFeatureDetails = styled.div`
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.08);
    text-align: center;
    align-items: center;
    width: 95%;
    padding: 40px 100px;
    margin: 0 auto 32px auto;

    @media (max-width: 1024px) {
        width: 100%;
        padding: 20px;
        text-align: left;
    }
`;

export const ProFeatureDescription = styled.div`
  color: #444444;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 25.4px;
  display: flex; flex-direction: row;

    @media (max-width: 1024px) {
        display: block;
    }
`;

export const Motivation = styled.div`
    font-size: 28px;
    text-align: center;
    
    @media (max-width: 1024px) {
        font-size: 24px;
    }
`;

export const PText = styled.div`
    font-size: 18px;
    margin-bottom: 30px;
    
    @media (max-width: 1024px) {
        font-size: 16px;
    }
`;

export const MySection = styled.section`
    @media (max-width: 1024px) {
        padding: 0;
    }
`;

export const MainDiv = styled.div`
    @media (max-width: 1024px) {
        padding: 3rem 0.5rem;
    }
`;


export const CompaniesSection = styled.div`
`;

export const Container = styled.div`
    margin: 1rem 1rem;
    position: relative;

  @media (max-width: 1024px) {
    padding: 0 1rem;
  }
`;

export const ChapterHeading = styled.h2`
    font-size: 36px;
    font-weight: 700;
    margin-top: 4rem;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

export const AbsolventCompanies = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

    > img {
        margin: 2rem;
        height: 3.5rem;
    }

    @media (max-width: 1024px) {
        > img {
            margin: 2rem;
            height: 3rem;
        }
    }

    @media (max-width: 768px) {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: repeat(4, 1fr);
        grid-row-gap: 4rem;
        justify-items: center;
        margin: 5rem 0;

        > img {
            margin: 0.5rem;
            height: 2rem;
        }
    }

`;

export const LineSVG = styled.svg`
  @media (max-width: 1024px) {
      display: none;
  }
`;

export const RefImage = styled.img`
  width: 300px;
  margin: 0 auto;
  text-align: center;
  display: block;

  @media (max-width: 1024px) {
    width: 160px;
  }
`;

export const ProFeatureDetailsTwo = styled(ProFeatureDetails)`
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,0.08);
    text-align: center;
    align-items: center;
    width: 95%;
    padding: 40px 100px;
    margin: 0 auto 32px auto;

    @media (max-width: 1024px) {
        width: 100%;
        padding: 20px;
        text-align: left;
    }
`;

export const ProFeatureName = styled.div`
  color: #00070B;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  margin-bottom: 30px;

    @media (max-width: 1024px) {
        font-size: 22px;
        line-height: 28px;
        text-align: left;
    }
`;

export const FeatureSection = styled.div`
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  margin: 30px 0 10px 0;
  line-height: 25.4px;
  text-align: center;

    @media (max-width: 1024px) {
        display: block;
    }
`;